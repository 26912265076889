<template>
  <LiefengContent>
    <template v-slot:title>商品管理</template>
    <template v-slot:toolsbarLeft> </template>
    <template v-slot:toolsbarRight>
      <Input
        :maxlength="20"
        v-model.trim="searchData.queryCondition"
        enter-button
        placeholder="按商品名称查询"
        style="width: 150px"
        @on-enter="getList"
      />
      <Button
        style="margin: 0 10px 0 10px"
        type="primary"
        icon="ios-search"
        @click="getList"
      >
        查询</Button
      ><Button
        style="margin: 0 10px 0 0"
        icon="ios-refresh"
        type="success"
        @click="rest"
      >
        重置</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="md-add"
        @click="addShopList"
        >新增商品</Button
      >
      <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button
        type="success"
        @click="$router.push('/sponsorindex')"
        v-if="$route.query.sponsorId"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="pageNumber"
        @hadlePageSize="getList"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
      <!-- 新增商品 -->
      <LiefengModal
        :title="title"
        width="540px"
        height="calc(100vh - 200px)"
        :value="addshop"
        @input="addShopFn"
      >
        <template v-slot:contentarea>
          <Form
            class="addform"
            :model="addformShop"
            ref="addformShop"
            :rules="shopValidate"
            label-position="right"
            :label-width="130"
            style="width: 520px"
          >
            <FormItem label="商品名称" prop="name">
              <Input
                v-model.trim="addformShop.name"
                :maxlength="50"
                style="width: 350px"
              />
            </FormItem>

            <FormItem label="商品类型" prop="prizeType">
              <Select v-model="addformShop.prizeType" style="width: 350px">
                <Option
                  :value="item.value"
                  v-for="(item, index) in prizeTypeList"
                  :key="index"
                  >{{ item.label }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="数量" prop="total">
              <InputNumber
                @on-change="
                  (val) => {
                    addformShop.total = Number(
                      addformShop.total.toString().replace('.', '')
                    );
                  }
                "
                :max="999999999"
                :min="1"
                v-model.number="addformShop.total"
                style="width: 350px"
              ></InputNumber>
            </FormItem>

            <FormItem label="单位" prop="unit">
              <Input
                v-model.trim="addformShop.unit"
                placeholder=""
                style="width: 350px"
                :maxlength="10"
              />
            </FormItem>
            <FormItem label="价值金额（元）" prop="price">
              <InputNumber
                :min="1"
                :max="999999999"
                v-model="addformShop.price"
                style="width: 350px"
              ></InputNumber>
            </FormItem>
            <FormItem label="商品图片">
              <img
                :src="addformShop.image"
                alt=""
                v-if="addformShop.image != ''"
                width="200"
                style="display: block; margin-bottom: 10px"
              />
              <Upload
                :before-upload="handleUpload"
                :data="uploadParams"
                :on-success="successUpload"
                :action="action"  
                :default-file-list="imageList"
                :format="['jpg', 'png', 'jpeg', 'gif']"
                :on-format-error="uploadFormatError"
                :on-remove="uploadRemove"
                accept=".jpg,.png,.jpeg,.gif"
              >
                <Button>上传图片</Button>
              </Upload>
            </FormItem>
            <FormItem
              label="赞助商"
              prop="sponsorId"
              v-if="title === '新增商品' && shopDisabled"
            >
              <Select
                v-model.trim="addformShop.sponsorId"
                style="width: 350px"
                :disabled="shopDisabled"
              >
                <Option
                  :value="item.sponsorId"
                  v-for="(item, index) in sponsorList"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="赞助商" prop="sponsorId" v-else>
              <Select
                v-model.trim="addformShop.sponsorId"
                style="width: 350px"
                :disabled="shopDisabled"
              >
                <Option
                  :value="item.sponsorId"
                  v-for="(item, index) in sponsorList"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="赞助时间">
              <DatePicker
                format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                :disabled="title === '修改商品' ? true : false"
                v-model="helpDefaultTime"
                placeholder="默认当前时间"
                style="width: 200px"
              ></DatePicker>
            </FormItem>
            <FormItem label="备注">
              <textarea
                v-model="addformShop.remark"
                :maxlength="50"
                cols="30"
                rows="2"
                style="width: 350px"
              ></textarea>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="addShopFn(false)">取消</Button>
          <Button
            type="primary"
            style="margin-left: 10px"
            @click="saveShop('addformShop')"
            >保存</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/sponsorshopmanage")
import { format } from "@/utils/formatTime";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      loading: false,
      imageList: [],
      imageSrc: "",
      uploadParams: {},
      action: "",
      uploadPath: "",
      uploadName: "",
      sponsorIdList: "",
      hideSearch: false,
      showDo: false, //false 为隐藏
      hideDel: true,
      hideEdit: true,
      hidenBread: true,
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      shopDisabled: false,
      talbeColumns: [
        {
          title: "商品名称",
          key: "name",
          width: 110,
          align: "center",
        },
        {
          title: "商品编码",
          key: "code",
          width: 110,
          align: "center",
        },
        {
          title: "数量",
          key: "total",
          width: 110,
          align: "center",
        },
        {
          title: "单位",
          key: "unit",
          width: 110,
          align: "center",
        },
        {
          title: "商品类型",
          key: "prizeType",
          minWidth: 110,
          align: "center",
        },
        {
          title: "价值(元)",
          key: "price",
          minWidth: 110,
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 80,

          align: "center",
        },
        {
          title: "赞助商",
          key: "sponsorName",
          align: "center",
          minWidth: 110,
        },
        {
          title: "赞助时间",
          key: "gmtCreate",
          minWidth: 120,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.edit(params);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.remove(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      searchData: {
        // name: "",
        queryCondition: "",
        sponsorId: "",
        page: "",
        pageSize: "",
      },
      addshop: false,
      title: "",
      addformShop: {
        name: "",
        prizeType: "",
        total: 1,
        // activityCode:"",
        unit: "",
        price: 1,
        sponsorId: this.$route.query.sponsorId
          ? this.$route.query.sponsorId
          : "",
        remark: "",
        gmtCreate: "",
        image: "",
      },
      shopValidate: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            type: "number",
            message: "单价不能为空",
            trigger: "change",
          },
        ],

        sponsorId: [
          {
            required: true,
            message: "赞助商不能为空",
            trigger: "change",
          },
        ],
        total: [
          {
            required: true,
            type: "number",
            message: "数量不能为空",
            trigger: "change",
          },
        ],
      },
      prizeTypeList: [
        {
          value: "1",
          label: "实物商品",
        },
        {
          value: "2",
          label: "线下消费券",
        },
        {
          value: "3",
          label: "线上消费券",
        },
      ],
      sponsorList: [],
      helpDefaultTime: this.helpDefaultTime ? this.helpDefaultTime : new Date(),
    };
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "商品名称",
            "商品编码",
            "数量",
            "单位",
            "商品类型",
            "价值(元)",
            "备注",
            "赞助商",
            "赞助时间",
          ];
          let filterVal = [
            "name",
            "code",
            "total",
            "unit",
            "prizeType",
            "price",
            "remark",
            "sponsorName",
            "gmtCreate",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "商品列表"
          );
        },
      });
    },
    addShopFn(status) {
      this.addshop = status;
    },
    uploadFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" + file.name + "格式不正确，请上传jpg,png,jpeg,gif格式的图片",
      });
    },
    handleUpload(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = Date.parse(new Date()) / 1000;
          this.autograph = res.data;
          this.action = window.vue.getProxy()["/oss"].target;
          this.uploadParams = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.uploadName = now + file.name;
          this.uploadPath = res.data.host + res.data.key;
          this.imageList = [];
        }
      });
    },
    //移除图片
    uploadRemove(file, fileList) {
      this.imageList = [];
      this.addformShop.image = "";
    },
    successUpload(res, file) {
      if (file.status == "finished") {
        this.$Message.success({
          background: true,
          content: "图片上传成功！",
        });
        this.imageList = [
          {
            name: file.name,
            url: this.uploadPath + this.uploadName,
          },
        ];
        this.addformShop.image = this.uploadPath + this.uploadName;
        // this.imageSrc = this.uploadPath + this.uploadName;
      }
    },
    getSponsorList() {
      this.$get("/old/api/pc/componentPrize/getSponsorList").then((res) => {
        if (res.code === "200") {
          this.sponsorList = res.dataList;
          this.sponsorList.forEach((item) => {
            item.sponsorId = String(item.sponsorId);
          });
        }
      });
    },
    rest() {
      this.searchData.queryCondition = "";
      this.getList();
    },
    getList(data) {
      this.loading = true;
      let params = {};
      if (data && data.page) {
        params = {
          page: data.page,
          pageSize: data.pageSize,
        };
      } else if (data && data.cancelable) {
        //点击查询时  查询返回第一页的数据
        params = {
          page: 1,
          pageSize: this.pageSize,
          queryCondition: this.searchData.queryCondition,
          sponsorId: this.searchData.sponsorId,
        };
        this.pageNumber = 1;
      } else {
        params = {
          page: this.pageNumber,
          pageSize: this.pageSize,
          queryCondition: this.searchData.queryCondition,
          sponsorId: this.searchData.sponsorId,
        };
      }

      this.$post("/old/api/pc/componentPrize/getPrizeGoodsPage", params)
        .then((res) => {
          if (res.code === "200") {
            res.dataList.forEach((item) => {
              item.gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss");
              item.prizeType =
                item.prizeType == 1
                  ? "实物商品"
                  : item.prizeType == 2
                  ? "线下消费券"
                  : item.prizeType == 3
                  ? "线上消费券"
                  : "";
            });
            this.total = res.maxCount;
            this.tableData = res.dataList;
            this.pageNumber = res.currentPage;
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: "数据获取失败",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setSelection() {},
    addShopList() {
      this.$refs.addformShop.resetFields();
      this.imageList = [];
      this.addformShop = {
        total: 1,
        price: 1,
      };
      this.addformShop = {
        total: 1,
        price: 1,
      };
      this.imageSrc = "";
      this.addshop = true;
      this.title = "新增商品";
      this.addformShop.sponsorId = this.$route.query.sponsorId;
      this.url = "/old/api/pc/componentPrize/addPrizeGoods";
    },
    //防抖
    debounce() {
      // console.log(3333);
      // var timeout = null;
      // return () => {
      //     console.log(2222);
      //     if (timeout !== null) {
      //         clearTimeout(timeout);
      //         console.log(111111);
      //     }
      //     timeout = setTimeout(this.saveShop("addformShop"), 1000);
      // };
    },
    saveShop(name) {
      if (this.addformShop.name == "" || this.addformShop.name == undefined) {
        this.$Message.error({
          background: true,
          content: "请输入商品名称",
        });
        return;
      } else if (
        this.addformShop.total == "" ||
        this.addformShop.total == undefined
      ) {
        this.$Message.error({
          background: true,
          content: "请输入商品数量",
        });
        return;
      } else if (
        this.addformShop.price == "" ||
        this.addformShop.price == undefined
      ) {
        this.$Message.error({
          background: true,
          content: "请输入价值金额",
        });
        return;
      } else if (
        this.addformShop.sponsorId == "" ||
        this.addformShop.sponsorId == undefined
      ) {
        this.$Message.error({
          background: true,
          content: "请输入赞助商",
        });
        return;
      }
      this.addformShop.gmtCreate = format(
        this.helpDefaultTime,
        "yyyy-MM-dd HH:mm:ss"
      );
      // 判断值为空时 赋值 ' '
      if (Object.values(this.addformShop).some((v) => v == "")) {
        for (let key in this.addformShop) {
          if (this.addformShop[key] == "") {
            this.addformShop[key] = " ";
          }
        }
      }
      this.$post(this.url, {
        ...this.addformShop,
        //图片路径
      })
        .then((res) => {
          if (res.code === "200") {
            this.$Message.success("操作成功");
            this.addshop = false;
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    importFile() {},
    edit(params) {
      // edit() {
      this.$refs.addformShop.resetFields();
      this.imageList = [];
      this.addshop = true;
      this.title = "修改商品";
      this.url = "/old/api/pc/componentPrize/updatePrizeGoods";
      let temp = JSON.parse(JSON.stringify(params.row));
      Object.keys(this.addformShop).forEach((item) => {
        this.addformShop[item] = temp[item];
      });
      this.addformShop = temp;
      this.helpDefaultTime = temp.gmtCreate;
      this.addformShop.total = temp.total;
      this.addformShop.price = Number(temp.price);
      this.addformShop.sponsorId = String(temp.sponsorId);
      this.addformShop.prizeType = temp.prizeType =
        temp.prizeType == "实物商品"
          ? "1"
          : temp.prizeType == "线下消费券"
          ? "2"
          : temp.prizeType == "线上消费券"
          ? "3"
          : "";
    },
    remove(params) {
      if (params) {
        let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
        this.pageNumber =
          this.pageNumber > totalPage ? totalPage : this.pageNumber;
        // console.log(this.pageNumber)
        this.pageNumber = this.pageNumber < 1 ? 1 : this.pageNumber;
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            this.$post("/old/api/pc/componentPrize/deleteSponsorGoods", {
              goodsId: params.goodsId,
            })
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("操作成功");
                  let data = {
                    pageNum: this.pageNumber,
                    pageSize: this.pageSize,
                  };
                  this.getList(data);
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
                if (res.code === "501") {
                  this.$Message.error(res.desc);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
        });
      } else {
        this.$Message.error("请先选择数据");
      }
    },
  },
  mounted() {
    if (this.$route.query.sponsorId) {
      this.addformShop.sponsorId = this.$route.query.sponsorId
        ? this.$route.query.sponsorId
        : "";
      this.searchData.sponsorId = this.addformShop.sponsorId;
      this.shopDisabled = true;
    } else {
      this.shopDisabled = false;
    }
    this.getSponsorList();

    this.getList();
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>